* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Poppins', sans-serif;
}
.apexcharts-toolbar {
  display: none !important;
}
.charts>div{
  width: 100% !important;
  height: 100% !important;
}
.apexcharts-canvas {
  color: black !important;
}