@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --text-color: rgb(30 41 59);
    --text-color-button: rgb(255, 255, 255);
    --background-color: rgb(248 250 252);
    --fill-color-button: rgb(59 130 246);
    --fill-color-hover: rgb(30 64 175);
    --fill-color-indicator:rgb(59 130 246);
  }
  /* .theme-dark {
    --text-color: rgb(248 250 252);
    --text-color-button: rgb(255, 255, 255);
    --background-color: rgb(30 41 59);
    --fill-color-button: rgb(59 130 246);
    --fill-color-hover: rgb(30 64 175);
    --fill-color-indicator:rgb(59 130 246);
  } */
  .theme-purple {
    
    --text-color: rgb(30 41 59);
    --text-color-button: rgb(255, 255, 255);
    --background-color: rgb(248 250 252);
    --fill-color-button:rgb(168 85 247);
    --fill-color-hover: rgb(126 34 206);
    --fill-color-indicator:rgb(168 85 247);
  }
  .theme-pink {
    
    --text-color: rgb(30 41 59);
    --text-color-button: rgb(255, 255, 255);
    --background-color: rgb(248 250 252);
    --fill-color-button:rgb(236 72 153);
    --fill-color-hover: rgb(190 24 93);
    --fill-color-indicator:rgb(236 72 153);
  }
  .theme-yellow {
    --text-color: rgb(30 41 59);
    --text-color-button: rgb(30 41 59);
    --background-color: rgb(248 250 252);
    --fill-color-button:rgb(234 179 8);
    --fill-color-hover: rgb(161 98 7);
    --fill-color-indicator:rgb(234 179 8);
  }
  .theme-green {
    
    --text-color: rgb(30 41 59);
    --text-color-button: rgb(255, 255, 255);
    --background-color: rgb(248 250 252);
    --fill-color-button:rgb(16 185 129);
    --fill-color-hover: rgb(4 120 87);
    --fill-color-indicator:rgb(16 185 129);
  }
}

.ReactTags__tagInput > input {
  border: 1px solid lightgray !important;
  width: 100% !important;
  padding: 5px 10px;
  border-radius: 5px !important;
}